<template>
  <div v-if="invoiceData == null">
    <b-row align-h="center">
      <b-spinner variant="primary" />
    </b-row>
  </div>
  <b-row
    v-else
    align-h="center"
    class="mt-2"
  >
    <b-col
      cols="12"
      :lg="$route.name != 'drs-invoice-view' ? 12 : 10"
      :md="$route.name != 'drs-invoice-view' ? 12 : 10"
    >
      <section
        class="invoice-preview-wrapper"
      >
        <b-row class="invoice-preview">
          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              no-body
              class="invoice-preview-card bg-invoice"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <b-col cols="6">
                    <div class="logo-wrapper">
                      <logo />
                    </div>
                  </b-col>

                  <!-- Header: Right Content -->
                  <div class="mt-md-0 mt-2">
                    <div class="text-right">
                      Slotenmaker DRS<br>
                      Kvk nr: 69483973<br>
                      Btw nr: 857889734<br>
                      Tel: 085 - 3035872<br>
                      Bank: NL77INGB0009268342<br>
                      Email: <a href="mailto:info@slotenmakerdrs.nl">info@slotenmakerdrs.nl</a><br>
                      Website: <a href="www.slotenmakerdrs.nl">www.slotenmakerdrs.nl</a>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                v-if="invoiceData.customer"
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="p-0"
                  >
                    <h6 class="mb-25">
                      <b-link
                        class="font-weight-bold"
                        :to="{
                          name: 'new-customer',
                          params: { id: invoiceData.customer.id },
                        }"
                      >
                        {{ invoiceData.customer.name }}
                      </b-link>
                    </h6>
                    <p class="card-text mb-25">
                      {{ invoiceData.customer.address }}, {{ invoiceData.customer.house_number }}
                    </p>
                    <b-card-text class="mb-0">
                      {{ invoiceData.customer.cp }} - {{ invoiceData.customer.city }}
                    </b-card-text>
                    <p class="card-text mb-0">
                      {{ invoiceData.customer.email }}
                    </p>
                    <p class="card-text mb-25">
                      Tel: {{ invoiceData.customer.phone }}
                    </p>
                    <h2 class="mb-2"><br>
                      {{ $t("menu.invoice") }}
                    </h2>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    xl="6"
                    cols="6"
                    class="p-0 mt-xl-0 mt-2 d-flex"
                  >
                    <h6 class="mb-2 ml-1">
                      {{ $t("invoice.invoice_number") }}:
                      <span class="invoice-number">#{{ invoiceData.code }}</span><br>
                      {{ $t("invoice.due_date") }}:
                      <span class="invoice-number">{{ invoiceData.date }}</span>
                    </h6>
                  </b-col>
                  <b-col
                    v-if="invoiceData.description"
                    cols="6"
                    xl="6"
                    class="p-0"
                  >
                    <b>{{ $t("agenda.comments") }}:</b>: {{ invoiceData.description }}
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Table -->
              <b-table-lite
                responsive
                :items="invoiceData.products"
                :fields="fields"
              >
                <template #cell(name)="data">
                  <b-card-text
                    v-if="data.item.name.includes('Corrigerende Factuur')"
                    class="font-weight-bold mb-25 text-right"
                  >
                    {{ data.item.name }}
                  </b-card-text>
                  <b-card-text
                    v-else
                    class="font-weight-bold mb-25"
                  >
                    {{ data.item.name }}
                  </b-card-text>
                </template>
                <template #cell(units)="data">
                  <b-card-text
                    v-if="!data.item.name.includes('Corrigerende Factuur')"
                    class="font-weight-bold mb-25"
                  >
                    {{ data.item.units }}
                  </b-card-text>
                </template>
                <template #cell(price)="data">
                  <b-card-text v-if="!data.item.name.includes('Corrigerende Factuur')">
                    €{{ formatNumberEuro(data.item.price) }}
                  </b-card-text>
                </template>
                <template #cell(total)="data">
                  <b-card-text
                    v-if="!data.item.name.includes('Corrigerende')"
                    class="font-weight-bold mb-25"
                  >
                    €{{
                      data.item
                        ? formatNumberEuro((data.item.price * data.item.units))
                        : 0 || 0
                    }}
                  </b-card-text>
                </template>
              </b-table-lite>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="12"
                    class="mt-md-12 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title text-right">
                          {{ $t("invoice.subtotal") }}:
                        </p>
                        <p class="invoice-total-amount">
                          €{{ subtotal }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("invoice.tax") }} 21%:
                        </p>
                        <p class="invoice-total-amount">
                          €{{ tax }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          €{{ formatNumberEuro(getTotal()) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col md="7">
                    <hr>
                    <p v-if="invoiceData.status === 'ACCREDITED' || invoiceData.status === 'NOT PAID' || invoiceData.status === 'PENDING'">
                      {{ $t("invoice.text_accredit") }}
                    </p>
                    <p>
                      {{ $t("invoice.footer_1") }}<br>
                      {{ $t("invoice.footer_2") }} {{ invoiceData.mechanic }}</p>
                    <p>{{ $t("invoice.footer_3") }}</p>
                    <p>{{ $t("invoice.footer_4") }}<br>
                      Slotenmaker DRS</p>
                  </b-col>
                  <b-col md="5">
                    <div class="img-footer">
                      <b-img
                        src="/erkend_politie_keurmerk.png"
                        fluid
                        alt="Responsive image"
                      />
                      <b-img
                        src="/skg.png"
                        fluid
                        alt="Responsive image"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <!-- <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('common.note') }}: </span>
            <span>{{ invoiceData.remark }}</span>
          </b-card-body> -->
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>
              <!-- Change invoice status --->
              <div v-if="$route.name != 'drs-invoice-view'">
                <!-- Button: Edit -->
                <b-button
                  v-if="invoiceData.budgets_id"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="warning"
                  class="mb-75"
                  block
                  :to="{
                    name: 'drs-budgets-form',
                    params: { id: invoiceData.budgets_id },
                  }"
                >
                  <h4 class="text-white line-height-inherit mb-0">
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="20"
                    />
                    {{ $t('budget.edit') }}
                  </h4>
                </b-button>
                <b-overlay
                  v-if="$route.name != 'drs-invoice-view' && invoiceData.status !== 'CANCELLED'"
                  :show="sendingEmail"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    :variant="`outline-${getStatus(invoiceData.status).variant}`"
                    class="mb-75"
                    block
                    @click="statusModal = true"
                  >
                    <b-row align-h="between">
                      <feather-icon
                        :icon="getStatus(invoiceData.status).icon"
                        size="16"
                      />
                      {{ getStatusTranslate(invoiceData.status) }}
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                      />
                    </b-row>
                  </b-button>
                </b-overlay>
                <b-overlay
                  v-else
                  :show="sendingEmail"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    :variant="`outline-${getStatus(invoiceData.status).variant}`"
                    class="mb-75 text-center"
                    block
                    @click="goToInvoiceRelated(invoiceData.invoice_negative_id)"
                  >
                    <feather-icon
                      icon="EyesIcon"
                      size="16"
                    />
                    <h4 class="line-height-inherit mb-0">
                      {{ $t("invoice.view_invoice_related") }}
                    </h4>
                  </b-button>
                </b-overlay>

                <!-- Send email -->
                <b-overlay
                  :show="sendingEmail"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    class="mb-75"
                    block
                    @click="sendBudget()"
                  ><h4 class="line-height-inherit mb-0">
                    <feather-icon
                      icon="MailIcon"
                      class="mr-50"
                      size="20"
                    />
                    {{ $t("budget.send_email") }}
                  </h4>
                  </b-button>
                </b-overlay>
              </div>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="printInvoice"
              ><h4 class="text-white line-height-inherit mb-0">
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-50"
                  size="20"
                />
                {{ $t("budget.print_download") }}
              </h4>
              </b-button>
            </b-card>
            <b-card v-if="$route.name != 'drs-invoice-view'">
              <span
                class="font-weight-bold"
              >{{ $t("budget.remark") }}:
              </span>
              <feather-icon
                v-if="!showComments"
                icon="EyeOffIcon"
                @click="showComments = !showComments"
              />
              <feather-icon
                v-if="showComments"
                icon="EyeIcon"
                @click="showComments = !showComments"
              />
              <b-form-textarea
                v-if="showComments"
                v-model="invoiceData.remark"
                rows="10"
                max-rows="30"
                plaintext
              />
            </b-card>
          </b-col>
        </b-row>
        <b-modal
          v-model="statusModal"
          class="modal fade bd-example-modal-xl"
          hide-header
          size="lg"
          :ok-title="$t('orders.modal.save')"
          :cancel-title="$t('common.cancel')"
          centered
          @ok="
            () => {
              changeStatus()
            }
          "
        >
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">
              {{ $i18n.t('invoice.change_status') }}
            </h5>

            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="productModal = false"
              />
            </div>
          </div>

          <hr>

          <!-- Body -->
          <div>

            <b-form-select
              v-model="newInvoiceStatus"
              :options="statusOptions"
              text-field="text"
              value-field="status"
            />
          </div>
        </b-modal>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BFormTextarea,
  BButton,
  BSpinner,
  BLink,
  BImg,
  VBToggle,
  BOverlay,
  BFormSelect,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import dbInvoice from '@/models/invoices'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BSpinner,
    BFormTextarea,
    BLink,
    BImg,
    Logo,
    BOverlay,
    BFormSelect,
  },
  data() {
    return {
      tax: 0,
      subtotal: 0,
      showComments: false,
      statusModal: false,
      newInvoiceStatus: {
        status: 'PENDING',
        variant: 'warning',
        icon: 'HelpCircleIcon',
      },
      invoiceData: null,
      sendingEmail: false,
      fields: [
        { key: 'name', label: this.$i18n.t('budget.name') },
        { key: 'units', label: this.$i18n.t('stocks.units') },
        { key: 'price', label: this.$i18n.t('common.price') },
        { key: 'total', label: this.$i18n.t('budget.total') },
      ],
      statusOptions: [
        {
          status: 'ACCREDITED',
          text: 'ACCREDITED',
          variant: 'primary',
          icon: 'PieChartIcon',
        },
        {
          status: 'REMINDER',
          text: 'HERRINERING',
          variant: 'info',
          icon: 'FileTextIcon',
        },
        {
          status: 'PAID',
          text: 'BETAALD',
          variant: 'success',
          icon: 'CheckCircleIcon',
        },
        {
          status: 'NOT PAID',
          text: 'NIET BETAALD',
          variant: 'warning',
          icon: 'HelpCircleIcon',
        },
        {
          status: 'PENDING',
          text: 'NOG TE BETALEN',
          variant: 'warning',
          icon: 'HelpCircleIcon',
        },
        {
          status: 'CANCELLED',
          text: 'GEANNULEERD',
          variant: 'danger',
          icon: 'XCircleIcon',
        },
      ],
    }
  },
  async created() {
    let invoiceId
    if (this.$route.name === 'drs-invoice-view') {
      invoiceId = this.$route.query.invoice_id
    } else {
      invoiceId = this.$route.params.id
    }
    if (this.$route.params.id === 'create') {
      this.invoiceData = {
        customer: {},
        products: [],
      }
    } else {
      this.invoiceData = await dbInvoice.get(invoiceId)
      this.invoiceData.products.forEach(p => {
        // eslint-disable-next-line no-param-reassign
        p.name = p.product
        // eslint-disable-next-line no-param-reassign
        delete p.product
      })
      this.invoiceData.created_at = this.formatDate(
        this.invoiceData.created_at,
      )
      this.invoiceData.date = this.formatDate(this.invoiceData.date)
      this.newInvoiceStatus = this.statusOptions.find(e => e.status === this.invoiceData.status).status
    }
    window.addEventListener('resize', this.initTrHeight)
  },
  computed: {
    isAdmin() {
      return (
        JSON.parse(window.localStorage.userData).role === 'ADMIN'
        || JSON.parse(window.localStorage.userData).role === 'ADMINISTRATION'
      )
    },
  },
  methods: {
    async changeStatus() {
      try {
        if (this.newInvoiceStatus === 'CANCELLED' && this.invoiceData.status !== 'CANCELLED') {
          this.cancelInvoice(this.invoiceData.invoice_id)
        } else {
          await dbInvoice.set({
            invoiceId: this.invoiceData.invoice_id,
            customer: {
              customerId: this.invoiceData.customer.id,
              name: this.invoiceData.customer.name,
              phone: this.invoiceData.customer.phone,
            },
            mechanicUsername: this.invoiceData.mechanic,
            concept: this.invoiceData.concept,
            date: this.invoiceData.date,
            description: this.invoiceData.description,
            status: this.invoiceData.status,
            invoice_status: this.newInvoiceStatus,
            budgetId: this.invoiceData.budgets_id,
          })
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.invoice.save_status')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
        this.invoiceData = await dbInvoice.get(this.invoiceData.invoice_id)
        this.invoiceData.products.forEach(p => {
        // eslint-disable-next-line no-param-reassign
          p.name = p.product
          // eslint-disable-next-line no-param-reassign
          delete p.product
        })
        this.invoiceData.created_at = this.formatDate(
          this.invoiceData.created_at,
        )
        this.invoiceData.date = this.formatDate(this.invoiceData.date)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.invoice.error_status')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
      this.statusModal = false
    },
    getStatusTranslate(status) {
      const getOptionStatus = Object.values(this.statusOptions)
      const statusDutch = getOptionStatus.find(i => i.status === status)
      return statusDutch.text
    },
    goToInvoiceRelated(id) {
      this.$router.push({ name: 'drs-invoice-preview', params: { id } })
      window.location.reload()
    },
    cancelInvoice(id) {
      this.$bvModal.msgBoxConfirm(this.$t('invoice.modal.body_cancel_invoice'), {
        title: this.$t('invoice.modal.tit_cancel_invoice'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.confirmationCancelInvoice(id)
          }
        })
    },
    async confirmationCancelInvoice(id) {
      await dbInvoice.cancelInvoice(id)
    },
    getStatus(status) {
      let stat
      this.statusOptions.forEach(s => {
        if (status === s.status) {
          stat = s
        }
      })

      return stat
    },
    async sendBudget() {
      try {
        this.sendingEmail = true
        await dbInvoice.sendEmail(this.invoiceData.invoice_id)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.send_invoice')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
        this.sendingEmail = false
      } catch (e) {
        this.sendingEmail = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.send_budget_error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
    async printInvoice() {
      await dbInvoice.printInvoice(this.invoiceData.invoice_id)
      // window.open(`${process.env.VUE_APP_BACKEND_URL}${this.invoiceData.urlPDF}`)
      window.open(`${this.invoiceData.urlPDF}`)
    },
    formatDate(dateString) {
      const p = dateString.split(/\D/g)
      return `${p[2]}-${p[1]}-${p[0]}`
    },
    getTotal() {
      const productList = this.invoiceData.products
      // eslint-disable-next-line no-unused-vars
      let totalProducts = 0
      productList.forEach(product => {
        totalProducts += product.price_with_tax * product.units
      })
      const subtotalExc = totalProducts / 1.21
      this.subtotal = this.formatNumberEuro(subtotalExc)
      const taxes = subtotalExc * 0.21
      this.tax = this.formatNumberEuro(taxes)
      return parseFloat(totalProducts)
    },
    formatNumberEuro(number) {
      return parseFloat(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.bg-invoice {
  background-image: url('/bg-invoice.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}
.invoice-total-wrapper {
  max-width: 16rem;
}
.img-footer {
  width: 100px;
  position: absolute;
  bottom: 0;
  display: inline-flex;
}
</style>
<style>
.invoice-total-wrapper {
  max-width: 17rem !important;
}
</style>
