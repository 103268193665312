<template>
  <div v-if="invoiceData == null">
    <b-row align-h="center">
      <b-spinner variant="primary" />
    </b-row>
  </div>
  <section
    v-else
    class="invoice-preview-wrapper"
  >
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card bg-invoice"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <b-col cols="6">
                <div class="logo-wrapper">
                  <logo />
                </div>
              </b-col>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="text-right">
                  Slotenmaker DRS<br>
                  Kvk nr: 69483973<br>
                  Btw nr: 857889734<br>
                  Tel: 085 - 3035872<br>
                  Bank: NL77INGB0009268342<br>
                  Email: <a href="mailto:info@slotenmakerdrs.nl">info@slotenmakerdrs.nl</a><br>
                  Website: <a href="www.slotenmakerdrs.nl">www.slotenmakerdrs.nl</a>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.customer"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="12"
                class="p-0"
              >
                <h6 class="mb-25">
                  {{ invoiceData.customer.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.customer.address }}, {{ invoiceData.customer.house_number }}
                </p>
                <b-card-text class="mb-0">
                  {{ invoiceData.customer.cp }} - {{ invoiceData.customer.city }}
                </b-card-text>
                <p class="card-text mb-0">
                  {{ invoiceData.customer.email }}
                </p>
                <p class="card-text mb-25">
                  Tel: {{ invoiceData.customer.phone }}
                </p>
                <h2 class="mb-2"><br>
                  {{ $t("menu.budget") }}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                xl="6"
                cols="6"
                class="p-0 mt-xl-0 mt-2 d-flex"
              >
                <h6 class="mb-2 ml-1">
                  {{ $t("budget.code") }}:
                  <span class="invoice-number">{{ invoiceData.code }}</span><br>
                  {{ $t("budget.date") }}:
                  <span class="invoice-number">{{ invoiceData.date }}</span>
                </h6>
              </b-col>
              <b-col
                v-if="invoiceData.description"
                cols="6"
                xl="6"
                class="p-0"
              >
                <b>{{ $t("agenda.comments") }}:</b>: {{ invoiceData.description }}
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceData.products"
            :fields="fields"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25 ">
                {{ data.item.name }}
              </b-card-text>
            </template>
            <template #cell(price)="data">
              <b-card-text class="total-symbol">€ {{ formatNumberEuro(data.item.price) }} </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text class="font-weight-bold mb-25 total-symbol">
                € {{
                  data.item
                    ? formatNumberEuro((data.item.price * data.item.units))
                    : 0 || 0
                }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Quote Footer -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="12"
                class="mt-md-12 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("invoice.subtotal") }}:
                    </p>
                    <p class="invoice-total-amount">
                      € {{ subtotal }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t("invoice.tax") }} 21%:
                    </p>
                    <p class="invoice-total-amount">
                      € {{ tax }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      € {{ formatNumberEuro(getTotal()) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="7">
                <hr>
                <p>
                  {{ $t("invoice.footer_1") }}<br>
                  {{ $t("invoice.footer_2") }}{{ invoiceData.mechanic }}</p>
                <p>{{ $t("invoice.footer_3") }}</p>
                <p>{{ $t("invoice.footer_4") }}<br>
                  Slotenmaker DRS</p>
              </b-col>
              <b-col md="5">
                <div class="img-footer">
                <b-img src="/erkend_politie_keurmerk.png" fluid alt="Responsive image" />
                <b-img src="/skg.png" fluid alt="Responsive image" />
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <!-- <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>{{ invoiceData.remark }}</span>
          </b-card-body> -->
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="secondary"
            class="mb-75"
            block
            @click="downloadInvoice"
          >
            Download
          </b-button> -->

          <!-- Button: Edit -->
          <b-button
            v-if="isAdmin"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="warning"
            class="mb-75"
            block
            :to="{
              name: 'drs-budgets-form',
              params: { id: invoiceData.budget_id },
            }"
          >
            <h4 class="text-white line-height-inherit mb-0">
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="20"
              />
              {{ $t('budget.edit') }}
            </h4>
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printBudget"
          >
            <h4 class="text-white line-height-inherit mb-0">
              <feather-icon
                icon="PrinterIcon"
                class="mr-50"
                size="20"
              />
              {{ $t('budget.print_download') }}
            </h4>
          </b-button>

          <!-- Button: Edit -->
          <b-overlay
            v-if="showAccept"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
              class="mb-75"
              block
              @click="showModal = true"
            ><h4 class="text-white line-height-inherit mb-0">
              <feather-icon
                icon="ThumbsUpIcon"
                class="mr-50"
                size="20"
              />
              {{ $t("budget.accept_budget.accepte") }}
            </h4>
            </b-button>
          </b-overlay>

          <h4
            v-else
            class="text-center"
          >
            <hr>
            {{ $t("budget.accept_budget.accepted") }}
          </h4>
        </b-card>
        <b-card v-if="!showAccept">
          <p>{{ $t("budget.accept_budget.message") }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="accept-modal"
      v-model="showModal"
      class="modal fade bd-example-modal-xl"
      hide-footer
      hide-header
      centered
      size="md"
    >
      <h4 class="mb-2 border-bottom">
        {{ $t('budget.accept_budget.title_details') }}
      </h4>
      <b-row>
        <b-col
          class="mb-2"
        >
          <table>
            <tr>
              <td
                width="45%"
                class="font-weight-bold"
              >
                {{ $t('customer.name') }}:
              </td>
              <td>{{ invoiceData.customer.name }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('customer.phone') }}:
              </td>
              <td>{{ invoiceData.customer.phone }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('customer.email') }}:
              </td>
              <td>{{ invoiceData.customer.email }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('customer.cp') }}:
              </td>
              <td>{{ invoiceData.customer.cp }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('customer.address') }}:
              </td>
              <td>{{ invoiceData.customer.address }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('customer.city') }}:
              </td>
              <td>{{ invoiceData.customer.city }}</td>
            </tr>
            <tr>
              <td />
              <td />
            </tr>
            <tr>
              <td class="font-weight-bold">
                {{ $t('budget.total_quote') }}:
              </td>
              <td>€ {{ formatNumberEuro(getTotal()) }}</td>
            </tr>
          </table>
          <p class="mt-2">
            {{ $t('budget.accept_budget.body_accepte_1') }}
          </p>
          <p class="mt-2">
            {{ $t('budget.accept_budget.body_accepte_2') }}<b>{{ currentDate() }}</b>.
          </p>
        </b-col>
      </b-row>
      <b-row
        align-h="end"
        class="px-1"
      >
        <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="showModal = false"
        >
          {{ $t('common.cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="acceptQuote"
        >
          {{ $t('budget.accept_budget.accepte') }}
        </b-button>
      </b-row>
    </b-modal>
    <budget-sidebar-send-budget />
    <budget-sidebar-add-payment />
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BImg,
  BSpinner,
  VBToggle,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import dbBudget from '@/models/budget'
import BudgetSidebarSendBudget from '../BudgetSidebarSendBudget.vue'
import BudgetSidebarAddPayment from '../BudgetSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BImg,
    BSpinner,
    Logo,
    BudgetSidebarAddPayment,
    BudgetSidebarSendBudget,
    BOverlay,
    BModal,
  },
  data() {
    return {
      invoiceData: null,
      idEncryp: '',
      subtotal: null,
      tax: null,
      showAccept: false,
      showModal: false,
      fields: [
        { key: 'name', label: this.$i18n.t('budget.name') },
        { key: 'units', label: this.$i18n.t('budget.units') },
        { key: 'price', label: this.$i18n.t('budget.price') },
        { key: 'total', label: this.$i18n.t('budget.total') },
      ],
    }
  },
  computed: {
    isAdmin() {
      if (!window.localStorage.userData) return false
      return JSON.parse(window.localStorage.userData).role === 'ADMIN'
    },
  },
  async created() {
    if (this.$route.name === 'drs-budget-view') this.invoiceData = await dbBudget.get(this.$route.query.budget_id)
    else this.invoiceData = await dbBudget.get(this.$route.params.id)
    this.invoiceData.products.forEach(p => {
      // eslint-disable-next-line no-param-reassign
      p.name = p.product
      // eslint-disable-next-line no-param-reassign
      delete p.product
    })
    this.invoiceData.created_at = this.formatDate(this.invoiceData.created_at)
    this.invoiceData.date = this.formatDate(this.invoiceData.date)
    window.addEventListener('resize', this.initTrHeight)
    if (this.invoiceData.status === 'SENT' || this.invoiceData.status === 'PENDING') this.showAccept = !this.showAccept
  },
  methods: {
    async printBudget() {
      const idBudget = !this.$route.query.budget_id ? this.$route.params.id : this.$route.query.budget_id
      await dbBudget.printBudget(idBudget)
      window.open(`${this.invoiceData.urlPDF}`)
    },
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
    getTotal() {
      const productList = this.invoiceData.products
      // eslint-disable-next-line no-unused-vars
      let totalProducts = 0
      productList.forEach(product => {
        totalProducts += product.price_with_tax * product.units
      })
      const subtotalExc = totalProducts / 1.21
      this.subtotal = this.formatNumberEuro(subtotalExc)
      const taxes = subtotalExc * 0.21
      this.tax = this.formatNumberEuro(taxes)
      return Number(totalProducts)
    },
    currentDate() {
      moment.locale('nl')
      const current = moment()
      const date = current.format('LL')
      return date
    },
    async acceptQuote() {
      try {
        this.showModal = false
        const budgetId = this.$route.query.budget_id !== undefined ? this.$route.query.budget_id : this.invoiceData.budget_id
        await dbBudget.acceptBudget(budgetId)
        this.showAccept = !this.showAccept
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.text.error')}`,
          },
        })
      }
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.invoice-total-wrapper {
  width: 100% !important;
  max-width: 16rem !important;
}
.bg-invoice {
  background-image: url('/bg-invoice.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}
.img-footer {
  width: 100px;
  position: absolute;
  bottom: 0;
  display: inline-flex;
}
.total-symbol {
  width: 100px;
}
</style>
