<template>
  <div v-if="invoiceData == null || products == null">
    <b-row align-h="center">
      <b-spinner variant="primary" />
    </b-row>
  </div>
  <section
    v-else
    class="invoice-add-wrapper"
  >
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <validation-observer ref="budget">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-column invoice-spacing mt-0"
                >
                  <!-- Header: Left Content -->
                  <b-row align-h="between">
                    <b-col
                      cols="12"
                      lg="5"
                      md="5"
                      sm="12"
                      xs="12"
                    ><!-- Col: Invoice To -->
                      <h6 class="mb-2">
                        {{ $t("invoice.quote_to") }}:
                      </h6>
                      <div
                        v-if="invoiceData.customer"
                        class="mt-1"
                      >
                        <h6 class="mb-25">
                          <b-link
                            class="font-weight-bold"
                            :to="{
                              name: 'new-customer',
                              params: { id: invoiceData.customer.id },
                            }"
                          >
                            {{ invoiceData.customer.name }}

                          </b-link>
                        </h6>
                        <b-card-text class="mb-25">
                          {{ invoiceData.customer.address }}, {{ invoiceData.customer.house_number }}
                        </b-card-text>
                        <b-card-text class="mb-0">
                          {{ invoiceData.customer.cp }} - {{ invoiceData.customer.city }}
                        </b-card-text>
                        <b-card-text class="mb-0">
                          {{ invoiceData.customer.email }}
                        </b-card-text>
                        <b-card-text class="mb-25">
                          Tel: {{ invoiceData.customer.phone }}
                        </b-card-text>
                      </div>
                      <hr>
                      <div class="invoice-number-date mt-md-0 mt-2">
                        <div class="mb-1 font-weight-bold">
                          <span class="mr-1">
                            {{ $t("budget.code") }}:
                          </span>
                          <span class="float-right">{{ invoiceData.code }}</span>
                          <br>
                          <span class="mr-1">
                            {{ $t("budget.date") }}:
                          </span>
                          <span class="float-right">{{ invoiceData.created_at }}</span>
                          <br>
                          <div>
                            <span class="mr-1">
                              Status: {{ invoiceData.status }}
                            </span>
                            <span
                              v-if="invoiceData.status === 'PROCESSING'"
                              class="text-danger"
                            > - {{ $t("budget.pending_material") }}</span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                      md="4"
                      sm="12"
                      xs="12"
                    >
                      <b-row>
                        <b-col cols="6">
                          <div class="align-items-center">
                            <span class="mt-1 mb-0 font-weight-bold">
                              {{ $t("budget.date") }}:
                            </span>
                            <p>
                              <validation-provider
                                #default="{ errors }"
                                name="Date"
                                rules="required"
                              >
                                <flat-pickr
                                  v-model="invoiceData.date"
                                  :state="errors.length > 0 ? false : null"
                                  class="form-control invoice-edit-input mw-100"
                                  :config="{ enableTime: false, dateFormat: 'd-m-Y' }"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </p>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div class="align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              name="Mechanic"
                              rules="required"
                            >
                              <span
                                for="invoice-data-sales-person"
                                class="mt-1 mb-0 font-weight-bold"
                              >{{ $t("user.mechanic") }}:</span>
                              <p>
                                <b-form-select
                                  v-model="invoiceData.mechanic_user_id"
                                  placeholder="Select Item"
                                  :state="errors.length > 0 ? false : null"
                                  value-field="id"
                                  text-field="name"
                                  :options="mechanics"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </p>
                            </validation-provider>
                          </div>
                        </b-col>
                      </b-row>
                      <!-- Description -->
                      <b-card-body class="invoice-padding pt-0 px-0">
                        <validation-provider
                          #default="{ errors }"
                          name="Description"
                        >
                          <span
                            class="font-weight-bold"
                          >{{ $t("budget.description") }}:
                          </span>
                          <b-form-textarea
                            v-model="invoiceData.description"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <div class="user-update">
                          <table>
                            <tr>
                              <td :width="`${isMovil ? '' : '43%'}`">
                                {{ $t("budget.created_by") }}:
                                <span v-if="isMovil"><br>
                                  {{ invoiceData.f_created_at }} - {{ invoiceData.created_by }}</span>
                              </td>
                              <span v-if="!isMovil">
                                {{ invoiceData.f_created_at }} - {{ invoiceData.created_by }}</span>
                              <td />
                            </tr>
                            <tr v-if="isMovil">
                              <td class="pt-1" /></tr>
                            <tr>
                              <td>{{ $t("budget.updated_by") }}:
                                <span v-if="isMovil"><br>
                                  {{ invoiceData.f_updated_at }} - {{ invoiceData.updated_by }}</span>
                              </td>
                              <td>
                                <span v-if="!isMovil">
                                  {{ invoiceData.f_updated_at }} - {{ invoiceData.updated_by }}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </b-card-body>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="3"
                      md="3"
                      sm="12"
                      xs="12"
                    >
                      <span class="mt-1 mb-0 font-weight-bold">
                        {{ $t("invoice.actions") }}:
                      </span>

                      <!-- Button: Print and Preview Quote -->
                      <div v-if="isMovil">
                        <b-button-group class="w-100">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :disabled="invoiceData.products.length > 0 ? false : disableSendQuote"
                            variant="primary"
                            class="mb-75"
                            @click="printQuotePdf()"
                          >
                            <feather-icon
                              icon="PrinterIcon"
                              class="mr-50"
                            />
                            PDF
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            :disabled="invoiceData.products.length > 0 ? false : disableSendQuote"
                            variant="warning"
                            class="mb-75"
                            :to="{
                              name: 'drs-budgets-preview',
                              params: { id: invoiceData.budget_id },
                            }"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              class="mr-50"
                            />
                            {{ $t("menu.preview") }}
                          </b-button>
                        </b-button-group>
                      </div>
                      <b-row v-else>
                        <b-col
                          md="6"
                          class="bt-pdf"
                        >
                          <b-overlay
                            rounded
                            opacity="0.6"
                            spinner-small
                            spinner-variant="primary"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :disabled="invoiceData.products.length > 0 ? false : disableSendQuote"
                              variant="primary"
                              class="mb-75"
                              block
                              @click="printQuotePdf()"
                            >
                              <feather-icon
                                icon="PrinterIcon"
                                class="mr-50"
                              />
                              PDF
                            </b-button>
                          </b-overlay>
                        </b-col>
                        <b-col
                          md="6"
                          class="bt-prw"
                        >
                          <b-overlay
                            rounded
                            opacity="0.6"
                            spinner-small
                            spinner-variant="primary"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :disabled="invoiceData.products.length < 1 || disableButtons ? disableButtons : false"
                              variant="warning"
                              class="mb-75"
                              block
                              :to="{
                                name: 'drs-budgets-preview',
                                params: { id: invoiceData.budget_id },
                              }"
                            >
                              <feather-icon
                                icon="EyeIcon"
                                class="mr-50"
                              />
                              {{ $t("menu.preview") }}
                            </b-button>
                          </b-overlay>
                        </b-col>
                      </b-row>

                      <!-- Button: Send Quote -->
                      <b-row>
                        <b-col>
                          <b-overlay
                            :show="sendingInvoice"
                            rounded
                            opacity="0.6"
                            spinner-small
                            spinner-variant="primary"
                          >
                            <b-button
                              v-if="invoiceData.status !== 'INVOICED' && invoiceData.status !== 'ACCEPTED'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :disabled="invoiceData.products.length < 1 || disableButtons ? disableButtons : false"
                              variant="outline-primary"
                              class="mb-75"
                              block
                              @click="confirmSendEmail()"
                            >
                              <feather-icon
                                icon="MailIcon"
                                class="mr-50"
                              />
                              {{ $t("budget.send_budget") }}
                            </b-button>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <!-- Button: Set to invoiced -->
                      <b-row>
                        <b-col>
                          <b-overlay
                            :show="creatingInvoice"
                            rounded
                            opacity="0.6"
                            spinner-small
                            spinner-variant="primary"
                          >
                            <b-button
                              v-if="invoiceData.status === 'ACCEPTED'"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              class="mt-1"
                              :disabled="creatingInvoice || disableButtons"
                              variant="primary"
                              block
                              @click="setInvoiceModal = true"
                            >
                              {{ $t("invoice.set_invoiced") }}
                            </b-button>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <hr>

                      <!-- Button: Save -->
                      <b-row>
                        <b-col>
                          <b-overlay
                            :show="savingBudget"
                            rounded
                            opacity="0.6"
                            spinner-small
                            spinner-variant="primary"
                          >
                            <b-button
                              v-if="invoiceData.status !== 'INVOICED'"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="success"
                              block
                              :disabled="savingBudget || disableButtons"
                              @click="saveBudget"
                            >
                              <feather-icon
                                icon="SaveIcon"
                                class="mr-25"
                              />
                              <span>{{ $t("common.save") }}</span>
                            </b-button>
                          </b-overlay>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                  <b-row align-h="between">
                    <!-- Header: Right Content -->
                    <b-col
                      cols="12"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class="pl-2"
                    />
                    <b-col
                      cols="12"
                      lg="6"
                      md="6"
                    />
                  </b-row>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <b-row
                  v-if="!isMovil"
                  class="flex-grow-1 px-1 py-1 header-color"
                >
                  <!-- Single Item Form Headers -->
                  <b-col
                    cols="12"
                    lg="7"
                  >
                    {{ $t("menu.product") }}
                  </b-col>
                  <b-col
                    cols="12"
                    lg="1"
                  >
                    {{ $t("stocks.units") }}
                  </b-col>
                  <b-col
                    cols="12"
                    lg="2"
                  >
                    {{ $t("common.price") }}
                  </b-col>
                  <b-col
                    cols="12"
                    lg="2"
                  >
                    {{ $t("budget.total") }}
                  </b-col>
                </b-row>
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.products"
                    :key="index"
                    ref="row"
                    class="pl-1"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col
                      cols="12"
                      :class="`${isMovil ? 'pl-0' : ''}`"
                    >
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div
                        class="d-flex pt-1"
                      >
                        <b-row class="flex-grow-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="7"
                            class="col-code"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-input-group
                                v-if="!isMovil"
                                :id="`${item.units > item.product_stock ? 'refproductns' : 'refproduct'}`"
                                :prepend="`${item.code ? item.code : item.product_code}`"
                              >
                                <b-form-input
                                  v-model="item.name"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="disableButtons"
                                />
                                <small :style="`color:${item.stock <= 0 || item.units > item.stock ? 'white' : 'red'}`">{{
                                  errors[0] }}</small>
                              </b-input-group>
                              <b-form-textarea
                                v-else
                                v-model="item.name"
                                :label="$t('menu.product')"
                                :state="errors.length > 0 ? false : null"
                                :disabled="disableButtons"
                              />
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="3"
                            lg="1"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Units"
                              rules="required|positive"
                            >
                              <label class="d-inline d-lg-none">{{
                                $t("stocks.units")
                              }}</label>
                              <b-form-input
                                ref="unititem"
                                v-model="item.units"
                                :state="errors.length > 0 ? false : null"
                                :disabled="disableButtons"
                                type="number"
                                class="mb-2"
                                @input="() => getTotal()"
                              />
                              <small :style="`color:${item.stock <= 0 || item.units > item.stock ? 'white' : 'red'}`">{{
                                errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="5"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Price"
                              rules="required"
                            >
                              <label class="d-inline d-lg-none">{{
                                $t("common.price")
                              }}</label>
                              <b-form-input
                                ref="priceunit"
                                v-model="item.price"
                                :state="errors.length > 0 ? false : null"
                                :disabled="disableButtons || !isAdmin"
                                type="number"
                                class="mb-2"
                              />
                              <small :style="`color:${item.stock <= 0 || item.units > item.stock ? 'white' : 'red'}`">{{
                                errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="4"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">{{
                              $t("budget.total")
                            }}</label>
                            <p class="mb-1">
                              €{{
                                item
                                  ? formatNumberEuro((item.price * item.units))
                                  : 0 || 0
                              }}
                            </p>
                          </b-col>
                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                          style="z-index: 555;"
                        >
                          <feather-icon
                            size="16"
                            :icon="!disableButtons ? 'XIcon' : ''"
                            class="cursor-pointer"
                            @click="removeItem(index, item.name)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr v-if="isMovil">
                </div>
                <hr>
                <b-row
                  align-h="start"
                  class="mx-0 pl-1 bt-actions"
                >
                  <b-button
                    v-if="btExtraGuarantee && invoiceData.status !== 'INVOICED'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    class="mr-1 mb-1"
                    :disabled="disableButtons"
                    @click="addNewItemGarantieForm"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    EXTRA
                  </b-button>
                  <b-button
                    v-if="invoiceData.status !== 'INVOICED'"
                    ref="bt_newproduct"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="outline-primary"
                    class="mr-1 mb-1"
                    :disabled="disableButtons"
                    @click="addNewItemInItemForm('new')"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                    {{ $t("invoice.add_item") }}
                  </b-button>
                  <b-button
                    v-if="invoiceData.status !== 'INVOICED'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    :disabled="disableButtons"
                    @click="addNewItemInItemForm('product')"
                  >
                    <feather-icon
                      icon="ListIcon"
                      size="16"
                    />
                    {{ $t("budget.add_product_from_list") }}
                  </b-button>
                </b-row>
                <b-row
                  class="mx-0 pl-1"
                >
                  <b-col
                    md="6"
                    sm="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Remark"
                    >
                      <span
                        class="font-weight-bold"
                      >{{ $t("budget.remark") }}:
                      </span>
                      <feather-icon
                        v-if="!showComments"
                        icon="EyeOffIcon"
                        @click="showComments = !showComments"
                      />
                      <feather-icon
                        v-if="showComments"
                        icon="EyeIcon"
                        @click="showComments = !showComments"
                      />
                      <b-form-textarea
                        v-if="showComments"
                        v-model="invoiceData.remark"
                        rows="6"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
              <hr class="m-0 p-0">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-12">
                <b-row>
                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <!-- Button: Save -->
                    <b-overlay
                      :show="savingBudget"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                    >
                      <b-button
                        v-if="invoiceData.status !== 'INVOICED' || invoiceData.status !== 'CANCELLED'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="success"
                        block
                        :disabled="savingBudget || disableButtons || invoiceData.status === 'INVOICED'"
                        @click="saveBudget"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="mr-25"
                        />
                        <span>{{ $t("common.save") }}</span>
                      </b-button>
                    </b-overlay>

                    <!-- Button: Send Invoice -->
                    <b-overlay
                      :show="sendingInvoice"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="pl-1"
                    >
                      <b-button
                        v-if="
                          invoiceData.budget_id &&
                            invoiceData.status !== 'INVOICED' &&
                            invoiceData.status !== 'ACCEPTED' &&
                            invoiceData.status !== 'CANCELLED'
                        "
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :disabled="sendingInvoice || !disableSendQuote || disableButtons"
                        variant="outline-primary"
                        class=""
                        block
                        @click="confirmSendEmail()"
                      >
                        <feather-icon
                          icon="MailIcon"
                          size="16"
                        />
                        {{ $t("budget.send_budget") }}
                      </b-button>
                    </b-overlay>

                    <!-- Button: Cancelled Offer -->
                    <b-overlay
                      v-if="invoiceData.status !== 'INVOICED'"
                      :show="savingBudget"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="warning"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="ml-1"
                        :variant="invoiceData.status !== 'CANCELLED' ? 'danger' : 'success'"
                        block
                        @click="changeStatusBudget"
                      >
                        <feather-icon
                          :icon="invoiceData.status !== 'CANCELLED' ? 'XCircleIcon' : 'RefreshCcwIcon'"
                          class="mr-25"
                        />
                        <span v-if="invoiceData.status !== 'CANCELLED'">{{ $t("invoice.cancel_budget") }}</span>
                        <span v-else>{{ $t("invoice.enable_budget") }}</span>
                      </b-button>
                    </b-overlay>
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("budget.total") }}:
                        </p>
                        <p class="invoice-total-amount">
                          €{{ total }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <hr>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <b-modal
      v-model="productModal"
      class="modal fade bd-example-modal-xl"
      hide-footer
      hide-header
      no-close-on-backdrop
      size="xl"
    >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">
          {{ $t("budget.choose_product") }}
        </h5>

        <!-- <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="productModal = false;"
          />
        </div> -->
      </div>

      <hr>

      <!-- Body -->
      <div>
        <products-list
          :data="products"
          :is-busy="isBusy"
          :invoice-data="invoiceData"
          @closeModal="productModal = false; getTotal()"
          @checkForDependencies="checkForDependencies"
        />
      </div>
    </b-modal>
    <b-modal
      id="setinvoice-modal"
      v-model="setInvoiceModal"
      class="modal fade bd-example-modal-xl"
      hide-footer
      hide-header
      centered
      size="md"
    >
      <h5>{{ $t('budget.modal.options_paid0') }}</h5>
      <b-row>
        <b-col
          class="mb-2"
        >
          <b-form-select
            v-model="invoiceData.pay"
            :options="typePaidInvoice"
            :placeholder="$t('budget.modal.options_paid0')"
          />
        </b-col>
      </b-row>
      <b-row
        align-h="end"
        class="px-1"
      >
        <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="setInvoiceModal = false"
        >
          {{ $t('common.cancel') }}
        </b-button>
        <b-button
          :disabled="!invoiceData.pay"
          variant="primary"
          @click="confirmSetInvoice"
        >
          {{ $t('common.save') }}
        </b-button>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BButtonGroup,
  BCardText,
  BForm,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BFormSelect,
  BLink,
  VBToggle,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ProductsList from '@/views/drs/budgets/ProductsList.vue'
import store from '@/store'
import dbProduct from '@/models/product'
import dbBudget from '@/models/budget'
import dbInvoice from '@/models/invoices'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BButtonGroup,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BFormSelect,
    BLink,
    BSpinner,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    ProductsList,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      resetModal: null,
      originalOffer: null,
      dateBackend: null,
      disableSendQuote: true,
      disableButtons: false,
      isBusy: false,
      productModal: false,
      setInvoiceModal: false,
      required,
      isMovil: isMobile,
      isNewProduct: false,
      btExtraGuarantee: true,
      products: null,
      invoiceData: null,
      sendingInvoice: false,
      savingBudget: false,
      totalBudget: 0,
      showComments: false,
      creatingInvoice: false,
      typePaidInvoice: [
        { value: 'Op rekening', text: this.$t('budget.modal.options_paid1') },
        { value: 'U heeft cash betaald', text: this.$t('budget.modal.options_paid2') },
        { value: 'U heeft met PIN betaald', text: this.$t('budget.modal.options_paid3') },
      ],
    }
  },
  computed: {
    total() {
      return this.getTotal()
    },
    mechanics() {
      const arr = this.$store.state.app.system?.users_map?.MECHANICS
      const map = new Map()
      if (arr !== undefined) {
        arr.forEach(obj => {
          Object.keys(obj).forEach(key => {
            map.set(key, obj[key])
          })
        })
      }

      const array = []
      if (map !== undefined) {
        map.forEach((val, key) => {
          array.push({ id: Number(key), name: val })
        })
      }
      return array
    },
    isAdmin() {
      return (
        JSON.parse(window.localStorage.userData).role === 'ADMIN'
        || JSON.parse(window.localStorage.userData).role === 'ADMINISTRATION'
      )
    },
  },
  async created() {
    this.isBusy = true
    if (this.$route.params.id === 'create') {
      this.invoiceData = {
        customer: {},
        products: [],
      }
    } else {
      this.invoiceData = await dbBudget.get(this.$route.params.id)
      this.invoiceData.date = this.formatDateBackend(this.invoiceData.date)
      if (this.invoiceData.status !== 'PENDING') this.disableSendQuote = false
      if (this.invoiceData.status === 'CANCELLED') this.disableButtons = true
      this.products = await dbProduct.get({ paginate: false })
      this.invoiceData.products.forEach(p => {
        // eslint-disable-next-line no-param-reassign
        p.name = p.product
        // eslint-disable-next-line no-param-reassign
        p.productId = p.products_id
        // eslint-disable-next-line no-param-reassign
        delete p.product
        // eslint-disable-next-line no-param-reassign
        delete p.products_id

        this.products.forEach(el => {
          if (p.name.toLowerCase() === el.name.toLowerCase()) {
            // eslint-disable-next-line no-param-reassign
            p.stock = el.stock ?? 0
          }
        })
      })
      this.resetModal = this.invoiceData.products
      this.invoiceData.created_at = this.formatDate(this.invoiceData.created_at)
    }
    this.products.forEach(e => {
      e.category = this.$store.state.app.system?.products.categories[
        e.products_categories_id
      ]
      e.brand = this.$store.state.app.system?.products.categories[
        e.products_brands_id
      ]
    })
    this.isBusy = false
  },
  methods: {
    async changeStatusBudget() {
      const newStatus = this.invoiceData.status !== 'CANCELLED' ? 'CANCELLED' : 'PENDING'
      this.$bvModal.msgBoxConfirm(this.$t('budget.modal.change_status_body'), {
        title: this.$t('budget.modal.change_status_body_title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.savingBudget = true
            this.disableSendQuote = true
            this.changeStatus(newStatus)
          }
        })
    },
    async changeStatus(newStatus) {
      await dbBudget.changeStatusBudget({
        budgetId: this.invoiceData.budget_id,
        status: newStatus,
      })
      this.savingBudget = true
      this.disableSendQuote = !this.disableSendQuote
      this.disableButtons = !this.disableButtons
      window.location.reload()
    },
    formatDate(dateString) {
      moment.locale('nl')
      return moment(dateString).format('D MMMM YYYY')
    },
    formatDateBackend(dateString) {
      const p = dateString.split(/\D/g)
      return `${p[2]}-${p[1]}-${p[0]}`
    },
    setNewProduct(index) {
      this.isNewProduct = true
      this.invoiceData.products[index].productId = null
      this.invoiceData.products[index].name = ''
      this.invoiceData.products[index].units = 1
      this.invoiceData.products[index].price = 0
    },
    async checkForDependencies(fatherProduct) {
      if (fatherProduct.productId !== undefined) {
        const dependencies = await dbProduct.getProductDependencies(fatherProduct.productId)
        if (Object.keys(dependencies).length > 0) {
          dependencies.forEach(d => {
            if (this.invoiceData.products.some(p => p.productId === d.child_product_id)) {
              const pToEdit = this.invoiceData.products.find(p => p.id === d.child_product_id)
              if (pToEdit.units === undefined) pToEdit.units = 0
              pToEdit.units += d.units
            } else {
              const product = this.products.find(p => p.id === d.child_product_id)
              // eslint-disable-next-line no-useless-concat
              this.$bvModal.msgBoxConfirm(`${this.$t('budget.product')}: ${product.name}`, {
                title: this.$t('budget.modal.dependencies_products_title'),
                size: 'sm',
                okVariant: 'primary',
                okTitle: this.$t('budget.modal.Yes'),
                cancelTitle: this.$t('budget.modal.No'),
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
              })
                .then(value => {
                  if (value) {
                    this.addNewItemInItemForm('new')
                    product.price = product.sell_price
                    product.units = d.units
                    this.invoiceData.products[this.invoiceData.products.length - 1] = product
                  }
                })
            }
          })
        }
      }
    },
    async saveBudget() {
      this.$refs.budget.validate().then(async success => {
        if (success) {
          try {
            this.savingBudget = true
            const { name } = this.mechanics.find(
              m => m.id === this.invoiceData.mechanic_user_id,
            )
            this.invoiceData.customer.customerId = this.invoiceData.customer.id
            this.dateBackend = this.formatDateBackend(this.invoiceData.date)
            const obj = {
              customer: this.invoiceData.customer,
              mechanicUsername: name,
              concept: this.invoiceData.concept,
              date: this.dateBackend,
              status: this.invoiceData.status ?? 'PENDING',
              budgetId: this.invoiceData.budget_id ?? null,
              products: this.invoiceData.products,
              remark: this.invoiceData.remark,
              description: this.invoiceData.description,
            }
            await dbBudget.set(obj)
            this.savingBudget = false
            this.disableSendQuote = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.budget.update')}`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$i18n.t('toast.text.success')}`,
              },
            })
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.error')}`,
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$i18n.t('toast.text.error')}`,
              },
            })
          }
        }
      })
    },
    async confirmSendEmail() {
      this.originalOffer = await dbBudget.get(this.$route.params.id)
      if (this.originalOffer.products.length !== this.invoiceData.products.length || this.invoiceData.products.length < 1) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.send_without_save_budget_title')}`,
            icon: 'XCircleIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.budget.send_without_save_budget')}`,
          },
        })
      } else {
        this.$bvModal.msgBoxConfirm(this.$t('budget.modal.body_email'), {
          title: this.$t('budget.send_budget'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('budget.modal.Yes'),
          cancelTitle: this.$t('budget.modal.No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
          .then(value => {
            if (value) this.sendQuote()
          })
      }
    },
    confirmSetInvoice() {
      this.$bvModal.msgBoxConfirm(this.$t('budget.modal.setinvoice_body'), {
        title: this.$t('budget.modal.setinvoice_title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) this.setInvoice()
        })
    },
    async sendQuote() {
      try {
        this.sendingInvoice = true
        await dbBudget.sendEmail(this.invoiceData.budget_id)
        this.sendingInvoice = false
        this.$router.push('/budgets/list')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.budget.send')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `${this.$i18n.t('toast.text.success')}`,
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.text.error')}`,
          },
        })
      }
    },
    async setInvoice() {
      try {
        this.creatingInvoice = true
        // Esto es para cambiar la key id a idBudgetProducts y product_id a idProduct
        // eslint-disable-next-line no-plusplus

        const dateInvoice = this.formatDateBackend(this.invoiceData.date)
        const obj = {
          customer: {
            customerId: this.invoiceData.customer.id,
            name: this.invoiceData.customer.name,
            phone: this.invoiceData.customer.phone,
          },
          mechanicUsername: this.invoiceData.mechanic,
          concept: this.invoiceData.concept,
          date: dateInvoice,
          status: 'INVOICED',
          invoice_status: 'PAID',
          budgetId: this.invoiceData.budget_id,
          description: this.invoiceData.description,
          products: this.invoiceData.products,
          methodPay: this.invoiceData.pay,
        }
        await dbInvoice.set(obj)
        this.creatingInvoice = false
        this.$router.push('/invoices/list')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.budget.invoice')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `${this.$i18n.t('toast.text.success')}`,
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.error')}`,
            icon: 'XIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.text.error')}`,
          },
        })
      }
    },
    getTotal() {
      this.invoiceData.total = 0
      this.invoiceData.products.forEach(p => {
        this.invoiceData.total += p.price !== undefined ? p.price * p.units : 0
      })
      this.invoiceData.totale = this.invoiceData.total
      this.invoiceData.total = this.formatNumberEuro(this.invoiceData.total)
      return this.invoiceData.total
    },
    updateItemForm(index, val, qty) {
      this.isNewProduct = false
      const { sell_price, name } = val
      // eslint-disable-next-line camelcase
      this.invoiceData.products[index].price = sell_price
      this.invoiceData.products[index].units = qty
      this.invoiceData.products[index].code = val.code
      this.invoiceData.products[index].name = name
      this.invoiceData.products[index].productId = val.id
    },
    addNewItemInItemForm(val) {
      if (!this.btExtraGuarantee) {
        this.$bvModal.msgBoxConfirm(this.$t('budget.modal.add_product_body'), {
          title: this.$t('budget.modal.add_product_title'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ok',
          hideHeaderClose: true,
          footerClass: 'pie',
          centered: true,
        })
      } else if (val === 'product') this.productModal = true
      else {
        this.$refs.form.style.overflow = 'hidden'
        this.invoiceData.products.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
        this.isNewProduct = true
      }
    },
    addNewItemGarantieForm() {
      const extraGuarantee = this.invoiceData.totale * 0.10
      const itemFormGuaranteeItem = {
        productId: null,
        product_code: 'EXTRA',
        name: 'Extra 3 jaar garantie',
        price: extraGuarantee.toFixed(2),
        units: 1,
      }
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.products.push(JSON.parse(JSON.stringify(itemFormGuaranteeItem)))
      const indexPrice = this.invoiceData.products.length - 1
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.priceunit[indexPrice].focus()
          this.$refs.bt_newproduct.focus()
        }, 350)
      })
      this.isNewProduct = true
      this.btExtraGuarantee = false
    },
    removeItem(index, extra) {
      if (extra === 'Extra garantie') this.btExtraGuarantee = true
      this.invoiceData.products.splice(index, 1)
    },
    async printQuotePdf() {
      await dbBudget.printBudget(this.$route.params.id)
      window.open(`${this.invoiceData.urlPDF}`)
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const itemFormBlankItem = {
      productId: null,
      product_code: '',
      name: '',
      price: 0,
      units: 1,
    }

    return {
      itemFormBlankItem,
    }
  },

}
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
    @import "@core/scss/vue/libs/vue-flatpicker.scss";

    .invoice-add-wrapper {
        .add-new-client-header {
            padding: $options-padding-y $options-padding-x;
            color: $success;

            &:hover {
                background-color: rgba($success, 0.12);
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "~@core/scss/base/pages/app-invoice.scss";
    @import "~@core/scss/base/components/variables-dark";

    .form-item-section {
        background-color: $product-details-bg;
    }

    .form-item-action-col {
        width: 27px;
    }

    .repeater-form {
        // overflow: hidden;
        transition: 0.35s height;
    }

    .v-select {
        &.item-selector-title,
        &.payment-selector {
            background-color: #fff;

            .dark-layout & {
                background-color: unset;
            }
        }
    }

    .dark-layout {
        .form-item-section {
            background-color: $theme-dark-body-bg;

            .row .border {
                background-color: $theme-dark-card-bg;
            }
        }
    }

    .col-code {
        #refproduct .input-group-text {
            min-width: 85px;
            background: $theme-red-input-color-stock;
        }

        #refproductns .input-group-text {
            min-width: 85px;
            background: $theme-red-input-color-no-stock;
            color: white;
        }
    }

    .bt-pdf {
        padding-right: 5px;
    }

    .bt-prw {
        padding-left: 5px;
    }

    .header-color {
        background-color: #f3f2f7;
        color: #6e6b7b;
        text-transform: uppercase;
        font-size: 0.857rem;
        font-weight: 600;
    }

    .invoice-padding.form-item-section {
        padding-top: 0;
    }

    .user-update {
        text-transform: capitalize;
        width: 350px;
        margin-top: 13px;
    }
</style>
<style>
.modal-footer.pie button:first-child {
  display: none;
}
</style>
